.App{
    height:100vh;
    width: 100vw;
    user-select: none; 
    overflow:hidden;
}
ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.menu{
    overflow: hidden;
    display:flex;
    gap:40px;
    align-items: center;
    height:70px;
    border-bottom: 2px solid black;
}

.list {
    display: flex;
    gap: 30px;
    height: 100%;
    align-items: flex-start; /* Align items to the start */
    width:100%;
}

.list > li {
    display: inline-block;
}

.list-container{
    width:100%;
    overflow:auto;
}

.list li {
    max-width: 100%;
}
.list a {
    display: block;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    color: #000;
    font-size: 18pt;
    
}
.list div {
    border-bottom: 3px solid rgba(0, 0, 0, 0.8);
    display:flex;
    align-items: center;
}
.selected div{
    font-weight: bold;
    border-color: #77EBB5;
    border-width: 5px;
}
.selected img{
    filter: invert(92%) sepia(12%) saturate(1494%) hue-rotate(83deg) brightness(94%) contrast(95%);
}

.icon{
    display:block;
    height:25px;
}
.logo {
    height:100%;
    padding:8px 20px;
    border-right:2px solid black;
}
.logo img{
    height:100%;
}
.user {
    display:flex;
    align-items: center;
    gap:16px;
    border-left: 2px solid black;
    padding:8px 20px;
    height:100%;
    font-size:18pt;
}
.user-picture{
    height:100%;
}
.caret{
    height:15px;
    margin-left:10px;
}

@media (max-width: 1000px){
    .NavText {
        display:none !important;
    }
}