.map-container{
    /* todo: this is not good */
    height: calc(100% - 70px); 
    position: relative;
}

.map-info-container{
    position: absolute;
    top: 30px;
    left: 15px;
    z-index: 1000;
    background-color: white;
    padding: 10px;
    border-radius: 20px;
    box-shadow: 0 5px 5px 0 rgba(0,0,0,0.3);
    border: 3px solid rgba(0, 0, 0, .5);
    background-clip: padding-box;
    display:flex;
    flex-direction: column;
    max-width:350px;
    padding:10px 20px;
    gap:20px;
}
.map-info{
    background-clip: padding-box;
    display:flex;
    justify-content: space-between;
    align-items: center;
}
.map-info:hover{
    background-color: #f0f0f0;
}
.map-info:active{
    background-color: #dddddd;
}
.map-info-text{
    /* bold */
    font-weight: bold;
    font-size: 14pt;
    max-width:250px;
}


.map-info:hover {
    background-color: #eee;
}

.caret {
    transition: transform 0.3s;
}

.caret.open {
    transform: rotate(180deg);
}



.legend-container{
    position: absolute;
    bottom: 50px;
    left: 15px;
    z-index: 1000;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 5px 5px 0 rgba(0,0,0,0.3);
    border: 3px solid rgba(0, 0, 0, .5);
    background-clip: padding-box;
    
}
.gradient{
    width:220px;
    height: 30px;
    background: linear-gradient(to right, #FF1111, #FFEC15, #33FF33);
    opacity: 0.7;
    border-radius: 10px;
    border: 2px solid rgba(0, 0, 0, .5);
    background-origin: border-box;

}
.value-container{
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}
.value-tickmarks{
    margin-top:4px;
    padding:0px 6px;
    display: flex;
    justify-content: space-between;
    font-size: 0.8em;
}
.value-tickmark{
    width: 2px;
    height: 8px;
    border-radius: 50%;
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity:0.7;
}
.small-tickmark{
    height:5px;
}